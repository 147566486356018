<template>
    <b-modal title="Add firmware package" v-model="addFirmwarePackageModalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <label>Terminal model</label>
                <v-select
                    v-model="addObject.terminal_model_id"
                    :reduce="model => model.id"
                    label="name"
                    :options="terminalModels"
                />
            </b-form-group>

            <b-form-group>
                <label>Version</label>
                <b-form-input v-model="addObject.version"/>
            </b-form-group>

            <FirmwarePackageFileSelector v-model="addObject.firmware_package_file_id"/>

            <b-form-group>
                <label>AB Upgrade Meta</label>
                <b-form-textarea v-model="addObject.ab_meta_info"/>
            </b-form-group>

            <b-form-group>
                <label>Upgrade Info</label>
                <b-form-textarea v-model="addObject.info"/>
            </b-form-group>

        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="addFirmwarePackageModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addFirmwarePackage" :disabled="addObject.firmware_package_file_id.length === 0">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormTextarea} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import FirmwarePackageFileSelector from '@/views/components/FirmwarePackageFileSelector'

    export default {
        props: {
            terminalModels: {
                type: Array,
                required: true
            }
        },
        components: {
            FirmwarePackageFileSelector,
            BButton,
            BFormGroup,
            BFormInput,
            BModal,
            BFormTextarea,
            vSelect
        },
        data() {
            return {
                addFirmwarePackageModalActive: false,
                addObject: {
                    terminal_model_id: '',
                    info: '',
                    version: '',
                    firmware_package_file_id: '',
                    ab_meta_info: ''
                }
            }
        },
        methods: {
            open() {
                this.addFirmwarePackageModalActive = true
            },
            addFirmwarePackage() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/firmware_package', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Firmware package added')
                    thisIns.addFirmwarePackageModalActive = false
                    thisIns.addObject = {
                        terminal_model_id: '',
                        info: '',
                        version: '',
                        firmware_package_file_id: '',
                        ab_meta_info: ''
                    }
                    thisIns.$emit('itemAdded')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Firmware packages">
                <b-button variant="black2" @click="$refs.addModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="firmwarePackages" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="$refs.editModal.open(firmwarePackagesRaw[props.row.index])" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button variant="danger" @click="removeFirmwarePackage(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <span v-if="props.row.terminal_model_type === 0"><feather-icon size="22" icon="HardDriveIcon" class="mr-1"/>  {{ props.formattedRow[props.column.field] }} </span>
                        <span v-else><feather-icon size="22" icon="MonitorIcon" class="mr-1"/>  {{ props.formattedRow[props.column.field] }} </span>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>

            </b-card>
        </b-overlay>

        <AddFirmwarePackageModal ref="addModal" :terminal-models="terminalModels" v-on:itemAdded="loadData"/>
        <EditFirmwarePackageModal ref="editModal" :terminal-models="terminalModels" v-on:itemSaved="loadData"/>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import AddFirmwarePackageModal from '@/views/FirmwarePackage/AddFirmwarePackageModal'
    import BasicTable from '@/views/components/BasicTable'
    import EditFirmwarePackageModal from '@/views/FirmwarePackage/EditFirmwarePackageModal'

    export default {
        components: {
            EditFirmwarePackageModal,
            AddFirmwarePackageModal,
            BCard,
            BOverlay,
            BButton,
            BasicTable
        },
        data() {
            return {
                dataLoaded: false,
                firmwarePackagesRaw: [],

                columns: [
                    {
                        label: 'Version',
                        displayType: 0,
                        field: 'version',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Terminal model',
                        displayType: 2,
                        field: 'terminal_model',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Terminal model codename',
                        displayType: 0,
                        field: 'terminal_model_codename',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Created',
                        displayType: 0,
                        field: 'time_created',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'File ID',
                        displayType: 0,
                        field: 'firmware_package_file_id',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                terminalModels: []
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/firmware_package')
                loadPromise.then(function(response) {
                    thisIns.firmwarePackagesRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const terminalModelsLoadPromise = this.$http.get('/api/management/v1/terminal_model')
                terminalModelsLoadPromise.then(function(response) {
                    thisIns.terminalModels = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, terminalModelsLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeFirmwarePackage(id) {
                const thisIns = this
                const deletePromise = this.$http.delete(`/api/management/v1/firmware_package/${  id}`)
                deletePromise.then(function() {
                    thisIns.$printSuccess('Firmware package removed')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        computed: {
            firmwarePackages() {
                return this.firmwarePackagesRaw.map((firmwarePackage, index) => {
                    return {
                        index,
                        id: firmwarePackage.id,
                        terminal_model_id: firmwarePackage.terminal_model_id,
                        terminal_model: firmwarePackage.terminal_model,
                        terminal_model_type: firmwarePackage.terminal_model_type,
                        terminal_model_codename: firmwarePackage.terminal_model_codename,
                        version: firmwarePackage.version,
                        info: firmwarePackage.info,
                        firmware_package_file_id: firmwarePackage.firmware_package_file_id,
                        time_created: this.moment(firmwarePackage.time_created).format('YYYY/MM/DD HH:mm')

                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>